<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls">
      <Btn :small="true" v-if="domain" @click.prevent.stop="$store.dispatch('updateDomainsData')" class="tablesave btn btn_blue">Вернуться к доменам</Btn>
      <Btn :small="true" v-if="names && (names.length > 100)" @click.prevent.stop="limited = !limited" class="tablesave btn btn_blue">{{ limited ? 'Показать все' : `Показать ${limit}` }}</Btn>
      <Btn :small="true" v-if="names && names.length" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
    </div>
    <div style="width: 100%; padding-top: 30px; margin-top: -30px;">
      <table class="table" ref="table" :data-cols-width="getColWidth">
        <thead>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>{{ domain ? 'URL' : 'Домен' }}</b></td>
            <td class="datecell" data-a-h="center" data-a-v="middle" v-for="date in compdates" :key="date" @click="toggleSort(date)">
              <b>{{ date }}</b>
              <span v-if="sortDate === date" class="sortarrow" :class="{ 'sortarrow_reverse': sortReverse }">↓</span>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="name in limitednames" :key="name + ' - ' + project.name">
            <td class="link" :class="{ 'link_url': domain }" data-a-v="middle" @click="setupDomain(name)" @contextmenu.prevent="copy(name, $event)">
              <div class="link__message"></div>
              <div class="link__name">{{ toReadableUrl(name) }}</div>
            </td>
            <td v-for="(date, index) in dates" :key="name + ' - ' + date" :data-a-h="'center'" data-a-v="middle" :data-t="'n'" :class="getTDcolor(name, index)">{{ data[name][index] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import Btn from './buttons/Btn.vue'
import TableToExcel from "@linways/table-to-excel"
import punycode from 'punycode'


export default {
  data: () => ({
    limited: true,
    limit: 500,
    sortDate: null,
    sortReverse: false,
  }),
  components: {
    Btn
  },
  computed: {
    ...mapState(['project', 'data', 'Role', 'domain', 'strictFilter']),
    ...mapGetters(['compdates', 'filters', 'dates']),

    names() {
      let res = []
      if (!this.sortDate || !this.compdates.includes(this.sortDate)) {
        for (let key in this.data) {
          res.push([key, this.data[key].reduce((a, b) => a + b, 0)])
        }
        res.sort((a, b) => b[1] - a[1])
      } else {
        const sortIndex = this.compdates.indexOf(this.sortDate)
        for (let key in this.data) {
          res.push([key, this.data[key][sortIndex]])
        }
        if (this.sortReverse)
          res.sort((a, b) => a[1] - b[1])
        else
          res.sort((a, b) => b[1] - a[1])
      }
      res = res.map(el => el[0])
      return res
    },

    filterednames() {
      if (!this.filters || !this.filters.length)
        return this.names
      else {
        if (this.strictFilter)
          return this.names.filter(el => this.filters.find(f => (el == f) || (this.toReadableUrl(el) ==(f))))
        else
          return this.names.filter(el => this.filters.find(f => el.includes(f) || this.toReadableUrl(el).includes(f)))
      }
    },
    limitednames() {
      if (this.limited)
        return this.filterednames.slice(0, this.limit)
      else
        return this.filterednames
    },
    getColWidth() {
      let base = "40"
      return base + this.dates.map(() => ",10").join("")
    },
  },
  methods: {
    toReadableUrl(str) {
      let firstpart = str.replace(/https?:\/\//,'').split('/')[0]
      return str.replace(firstpart, punycode.toUnicode(firstpart))
    },
    toggleSort(date) {
      if (this.sortDate === date) {
        this.sortReverse = !this.sortReverse 
      } else {
        this.sortDate = date 
        this.sortReverse = false
      }
    },
    getTDcolor(name, index) {
      if (!name || !index || !this.data)
        return ''
      let vals = [parseInt(this.data[name][index - 1]), parseInt(this.data[name][index])]
      if (vals.includes(NaN) || (vals[0] == vals[1]))
        return ''
      if (vals[1] < vals[0])
        return 'red'
      else
        return 'green'
    },
    copy(name, event) {
      let link = event.target.closest('.link')
      let message = link.querySelector('.link__message')
      let range = document.createRange()
      range.selectNode(link.querySelector('.link__name'))
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      clearTimeout(message.timeout)
      message.innerHTML = 'Скопировано!'
      message.timeout = setTimeout(() => message.innerHTML = '', 1000)
    },
    save() {
      this.$nextTick(() => {
        try {
          TableToExcel.convert(this.$refs.table, {
            name: this.project.name + ".xlsx",
            sheet: {
              name: "Данные"
            }
          })
        } catch (err) {
          console.error(err)
        }
      })
    },
    setupDomain(name) {
      if (!this.domain)
        this.$store.dispatch('updateDomainData', name)
    }
  },
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd
.sortarrow
  line-height: 0
  color: colors.$blue
  font-weight: 900
  margin-left: 4px
  font-size: 20px
  display: inline-block
  &_reverse
    transform: scale(1,-1) translateY(-3px)
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  tbody td
    z-index: 50 !important
    &.green
      background: #e6ffe6 !important
    &.red
      background: #ffe6e6 !important
  tbody td:hover
    z-index: 51 !important
  thead
    position: sticky
    top: 0
    z-index: 4
    &::after
      content: ''
      background: white 
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      top: 0
      display: block
      z-index: -1
    td
      background: white
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:first-child
    width: 300px
    min-width: 300px
    max-width: 300px
  tr
    font-weight: 500
    white-space: nowrap
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  .link__message:empty
    display: none !important
  .link__message:not(:empty) ~ *
    display: none !important
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      
      &:hover 
        overflow: visible
        padding: 0
        font-weight: 700
        &:not(.link_url)
          cursor: pointer
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: relative
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: red
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>