<template>
  <select class="select" v-model="f">
    <option value=""></option>
    <option v-for="p in folders" :value="p" :key="p">{{ p }}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url', 'folder', 'folders']),
    f: {
      get() {
        return this.folder
      },
      set(v) {
        this.$store.commit('setFolder', v)
      }
    }
  }
}
</script>

<style lang="sass">
</style>