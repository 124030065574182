<template>
  <Btn :title="tooltip" @click.stop="action" class="btn">
    <span v-if="mode == 'create'">+</span>
    <svg v-if="mode == 'delete'" stoke-width="55" viewBox="0 0 25 25">
      <g transform='translate(3.966 3.568)'>
        <path d='M0-.001l17.435 18.213M0 18.212L17.435 0' stroke-width='4.4' stroke-linecap='round'></path>
      </g>
    </svg>
  </Btn>
</template>

<script>
import { mapState } from 'vuex';
import Btn from './Btn.vue';
export default {
  props: {
    mode: String
  },
  components: {
    Btn,
  },
  methods: {
    action() {
      if (this.mode == 'create')
        return this.createFolder()
      if (this.mode == 'delete')
        return this.deleteFolder()
    },
    async createFolder() {
      let name = prompt('Укажите имя папки')
      this.$store.commit('toggleLoad', 'Folderbtn')
      await fetch(this.url + 'folder', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
      }).then(() => {
        this.$store.dispatch('updateFolders')
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'Folderbtn')
    },
    async deleteFolder() {
      if (!this.folder) return alert('Папка не указана')
      if (!confirm('Удалить папку ' + this.folder + '?'))
        return
      this.$store.commit('toggleLoad', 'Folderbtn')
      await fetch(this.url + 'folder', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: this.folder })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
      }).then(() => {
        this.$store.commit('setFolder', null)
        this.$store.dispatch('updateFolders')
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'Folderbtn')
    },
  },
  computed: {
    ...mapState(['url', 'folder']),
    tooltip() {
      if (this.mode == 'create') return 'Создать папку'
      if (this.mode == 'delete') return 'Удалить папку'
      return ''
    }
  }
}
</script>

<style lang="sass" scoped>
.btn
  font-size: 25px
  svg
    display: block
    width: 15px
</style>