import { createStore } from 'vuex'
import LZUTF8 from 'lzutf8'
import punycode from 'punycode'


export default createStore({
  state: {
    url: '',
    loads: {},
    top: 10,
    filters: '',
    loadFilters: '',
    datefilers: [0, Infinity],
    strictFilter: false,
    domain: null,
    group: null,
    folder: null,
    project: null,
    folders: [],
    projects: [],
    allusers: [],
    data: {},
    dates: [],
    Role: null,
    toReadableUrl: (str) => {
      let firstpart = str.replace(/https?:\/\//,'').split('/')[0]
      return str.replace(firstpart, punycode.toUnicode(firstpart))
    },

  },
  mutations: {
    setDateFilters(state, values) {
      state.datefilers = values
    },
    setTop(state, top) {
      state.top = top
    },
    setGroup(state, group) {
      state.group = group
    },
    setLoadFilters(state, loadFilters) {
      state.loadFilters = loadFilters
    },
    setFilters(state, filters) {
      state.filters = filters
    },
    setStrictFilter(state, strictFilter) {
      state.strictFilter = strictFilter
    },
    setDomain(state, domain) {
      state.domain = domain
    },
    setData(state, data) {
      state.data = data
    },
    setDates(state, dates) {
      state.dates = dates
    },
    setAllUsers(state, users) {
      state.allusers = users
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setFolders(state, folders) {
      state.folders = folders
    },
    setFolder(state, folder) {
      state.folder = folder
    },
    setProject(state, value) {
      state.project = value
      if ((value === null) || (value === undefined)) {
        state.dates = []
        state.data = {}
        state.domain = null
        state.group = null
        state.loadFilters = ''
      } else {
        if (value.filters && value.filters.length)
          state.loadFilters = value.filters.join('\n')
        else
          state.loadFilters = ''
      }
    },
    setProjects(state, value) {
      state.projects = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
      commit('setFolder', null)
      commit('setProject', null)
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', {credentials: 'include'})
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async updateAllUsers({ state, commit }) {
      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users', {credentials: 'include'})
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async updateFolders({ state, commit, dispatch }) {
      commit('toggleLoad', 'updateFolders')

      await fetch(state.url + 'folders', {credentials: 'include'})
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setFolders', list)
          dispatch('updateProjects')
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateFolders')
    },
    async updateProjects({ state, commit }) {
      if (!state.folder) {
        return commit('setProject', null)
      }
      commit('toggleLoad', 'updateProjects')
      await fetch(state.url + 'projects' + `?folder=${state.folder}`, {credentials: 'include'})
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setProjects', list)
          console.log(list)
          if (state.project) {
            let found = list.find(el => el.name == state.project.name)
            console.log(found)
            commit('setProject', found || null)
          }
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateProjects')
    },
    async sendFiles({ state, commit, dispatch }, data) {
      commit('toggleLoad', 'sendFiles')
      await fetch(state.url + 'data', {
        credentials: 'include',
        method: 'POST',
        body: data,
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          await dispatch('updateData')
          return res.text()
        })
        .then(text => {
          if (text && text != 'OK')
            alert(text)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'sendFiles')
    },
    async deleteGroup({ state, commit, dispatch }) {
      if (!confirm(`Удалить группу ${state.group} и вместе со всеми данными?`))
        return
      commit('toggleLoad', 'deleteGroup')
      await fetch(state.url + 'group', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          project: state.project.name,
          folder: state.folder,
          group: state.group
        })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        commit('setDomain', null)
        commit('setData', {})
        commit('setDates', [])
        commit('setGroup', null)
        await dispatch('updateProjects')
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'deleteGroup')
    },
    async deleteExcept({ state, commit, getters, dispatch }) {
      let exceptions = getters.loadFilters
      if (!exceptions || !exceptions.length) return alert('Не указаны домены-исключения')
      if (!confirm(`Отчистить проект от всех данных кроме доменов ${exceptions.join(', ')}?`))
        return
      commit('toggleLoad', 'deleteExcept')
      await fetch(state.url + 'domains', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          project: state.project.name,
          folder: state.folder,
          exceptions
        })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        commit('setDomain', null)
        commit('setData', {})
        commit('setDates', [])
        await dispatch('updateData')
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'deleteExcept')
    },
    async updateDomainData({ state, commit }, domain) {
      commit('toggleLoad', 'updateDomainData')
      if (!domain)
        domain = state.domain
      await fetch(state.url + 'getdomaindata', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, folder: state.folder, group: state.group, dname: domain })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(ddis => {
        let data = {}, dates = {}
        for (let i = 0; i < ddis.length; i++) {
          dates[ddis[i].date] = true
          ddis[i].data = JSON.parse(LZUTF8.decompress(ddis[i].data, { inputEncoding: 'Base64' }))
        }
        dates = Object.keys(dates).map(el => parseInt(el)).sort((a, b) => a - b)
        for (let i = 0; i < ddis.length; i++) {
          let d = ddis[i].data
          for (let url in d) {
            if (!data[url])
              data[url] = new Array(dates.length).fill(0)
            data[url][dates.indexOf(ddis[i].date)] += ddis[i].data[url]
          }
        }
        commit('setData', data)
        commit('setDates', dates)
        commit('setDomain', domain)

      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateDomainData')
    },
    async updateDomainsData({ state, commit }) {
      commit('toggleLoad', 'updateDomainsData')

      await fetch(state.url + 'getdata', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, folder: state.folder, group: state.group })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(domains => {
        let data = {}, dates = {}
        for (let i = 0; i < domains.length; i++)
          dates[domains[i].date] = true
        dates = Object.keys(dates).map(el => parseInt(el)).sort((a, b) => a - b)
        for (let i = 0; i < domains.length; i++) {
          let d = domains[i]
          if (!data[d.name])
            data[d.name] = new Array(dates.length).fill(0)
          data[d.name][dates.indexOf(d.date)] += d.phrasecount
        }
        commit('setData', data)
        commit('setDates', dates)
        commit('setDomain', null)
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateDomainsData')
    },
    async updateData({ state, commit, dispatch }) {
      if (!state.group) return
      commit('toggleLoad', 'updateData')
      if (state.domain)
        await dispatch('updateDomainData')
      else
        await dispatch('updateDomainsData')
      commit('toggleLoad', 'updateData')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    dates(state) {
      return state.dates.filter(el => (el >= state.datefilers[0]) && (el <= state.datefilers[1]))
    },
    compdates(state, getters) {
      return getters.dates.map(el => {
        //let yyyy = Math.floor(el / 500)
        let mm = ('' + (Math.floor((el % 500) / 32))).padStart(2, '0')
        let dd = ('' + (el % 500 % 32)).padStart(2, '0')
        return `${dd}.${mm}`
      })
    },
    filters(state) {
      return state.filters.split('\n').map(el => el.trim()).filter(el => el)
    },
    loadFilters(state) {
      return state.loadFilters.split('\n').map(el => el.trim()).filter(el => el)
    },
    topnames(state, getters) {
      class MinHeap {
        constructor() {
          this.heap = [];
        }

        // Helper Methods
        getLeftChildIndex(parentIndex) {
          return 2 * parentIndex + 1;
        }
        getRightChildIndex(parentIndex) {
          return 2 * parentIndex + 2;
        }
        getParentIndex(childIndex) {
          return Math.floor((childIndex - 1) / 2);
        }
        hasLeftChild(index) {
          return this.getLeftChildIndex(index) < this.heap.length;
        }
        hasRightChild(index) {
          return this.getRightChildIndex(index) < this.heap.length;
        }
        hasParent(index) {
          return this.getParentIndex(index) >= 0;
        }
        leftChild(index) {
          return this.heap[this.getLeftChildIndex(index)];
        }
        rightChild(index) {
          return this.heap[this.getRightChildIndex(index)];
        }
        parent(index) {
          return this.heap[this.getParentIndex(index)];
        }

        swap(indexOne, indexTwo) {
          const temp = this.heap[indexOne];
          this.heap[indexOne] = this.heap[indexTwo];
          this.heap[indexTwo] = temp;
        }

        peek() {
          if (this.heap.length === 0) {
            return null;
          }
          return this.heap[0];
        }

        remove() {
          if (this.heap.length === 0) {
            return null;
          }
          const item = this.heap[0];
          this.heap[0] = this.heap[this.heap.length - 1];
          this.heap.pop();
          this.heapifyDown();
          return item;
        }

        add(item) {
          this.heap.push(item);
          this.heapifyUp();
        }

        heapifyUp() {
          let index = this.heap.length - 1;
          while (this.hasParent(index) && this.parent(index) > this.heap[index]) {
            this.swap(this.getParentIndex(index), index);
            index = this.getParentIndex(index);
          }
        }

        heapifyDown() {
          let index = 0;
          while (this.hasLeftChild(index)) {
            let smallerChildIndex = this.getLeftChildIndex(index);
            if (this.hasRightChild(index) && this.rightChild(index) < this.leftChild(index)) {
              smallerChildIndex = this.getRightChildIndex(index);
            }
            if (this.heap[index] < this.heap[smallerChildIndex]) {
              break;
            } else {
              this.swap(index, smallerChildIndex);
            }
            index = smallerChildIndex;
          }
        }

        getHeap() {
          return this.heap
        }
      }

      let data = state.data, n = state.top, result = [], keys = Object.keys(data)
      if (getters.filters && getters.filters.length) {
        if (state.strictFilter)
          keys = keys.filter(el => getters.filters.find(f => (el == f) || (state.toReadableUrl(el) == f)))
        else
          keys = keys.filter(el => getters.filters.find(f => el.includes(f) || state.toReadableUrl(el).includes(f)))
      }

      for (let i = 0; i < getters.dates.length; i++) {
        let heap = new MinHeap(), arr = heap.getHeap()
        for (let key of keys) {
          let v = data[key][i]
          if (arr.length < n)
            heap.add(v)
          else {
            if (v > heap.peek()) {
              heap.remove()
              heap.add(v)
            }
          }
        }

        for (let key of keys) {
          if (arr.includes(data[key][i]))
            result.push(key)
        }
      }
      return new Array(...new Set(result))

    }
  }
})
